<template>
  <Layout>
    <template v-if="this.$route.name == 'add-podcast'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="podcast"
              :finishButtonText="this.$route.name == 'add-podcast' ? 'Save Data' : 'Update Data'"
              back-button-text="Go Back!" next-button-text="Go Next!">
              <tab-content title="Podcast Details" icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()">
                <div class="row">
                  <b-form-group class="col-6" id="input-group-1">
                    <label for="input-1">Podcast Heading <span style="color: red">*</span></label>
                    <b-form-input id="input-1" v-model="form.title" placeholder="Enter Podcast Heading" :class="{
                      'is-invalid': submitted && $v.form.title.$invalid,
                    }"></b-form-input>
                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                      Podcast Heading is required.
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-3" class="col-6 position-relative">
                    <label for="input-3">Audio URL <span style="color: red">*</span></label>
                    <b-form-input :maxlength="250" id="input-3" v-model="form.audio_link"
                      placeholder="Enter Audio URL [Eg: 663251034]" :class="{
                        'is-invalid': submitted && $v.form.audio_link.$invalid,
                      }"></b-form-input>
                    <div v-if="submitted && !$v.form.audio_link.required" class="invalid-feedback">
                      Audio URL is required.
                    </div>
                    <div class="text-right">
                      <p v-if="form.audio_link" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.audio_link.length !== 250,
                        'badge-danger': form.audio_link.length === 250
                      }">
                        You typed
                        {{ form.audio_link.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </div>

                <b-form-group id="input-group-4" label="Tell Us More About Podcast" label-for="input-4">
                  <ckeditor v-model="form.description"></ckeditor>
                </b-form-group>

                <b-form-group id="input-group-2" label="Thumbnail Image [Upload Max File Size : 2MB]"
                  label-for="input-2">
                  <b-form-file id="input-2" accept="image/*" placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name')" ref="image_name"></b-form-file>
                  <template v-if="$route.name == 'edit-podcast' && edit.image_name_url">
                    <img :src="edit.image_name_url" width="128px" height="128px"
                      style="object-fit: contain; margin-top: 5px" />
                  </template>
                  <template v-if="image_name_url">
                    <img :src="image_name_url" width="128px" height="128px" ref="image_name_url"
                      style="object-fit: contain; margin-top: 5px" />
                  </template>
                </b-form-group>

              </tab-content>

              <tab-content title="Add Tags" icon="fa fa-tag" :before-change="() => validateFormTwo()">
                <div class="row">
                  <b-form-group class="col-6">
                    <label for="comm">Community <span style="color: red">*</span></label>
                    <b-form-select id="comm" v-model="form.community_id" :options="community" value-field="id"
                      text-field="title" :class="{
                        'is-invalid': store && $v.form.community_id.$invalid,
                      }">
                    </b-form-select>
                    <div v-if="store && !$v.form.community_id.required" class="invalid-feedback">
                      Community is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="input-2">Partner <span style="color: red">*</span></label>
                    <b-form-select id="in-partner" v-model="form.partner_id" :options="partner" value-field="id"
                      text-field="title" :class="{
                        'is-invalid': store && $v.form.partner_id.$invalid,
                      }">
                    </b-form-select>
                    <div v-if="store && !$v.form.partner_id.required" class="invalid-feedback">
                      Partner is required.
                    </div>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group class="col-6 position-relative" id="input-group-5" label="Meta Title"
                    label-for="input-5">
                    <b-form-input :maxlength="190" id="input-5" v-model="form.meta_title"
                      placeholder="Enter Meta Title"></b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_title.length !== 190,
                          'badge-danger': form.meta_title.length === 190
                        }">
                        You typed
                        {{ form.meta_title.length }} out of 190 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6 position-relative" id="input-group-7" label="Meta Keywords" label-for="input-7">
                    <b-form-input id="input-7" :maxlength="250" v-model="form.meta_keywords" placeholder="Enter Meta Keywords">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_keywords.length !== 250,
                          'badge-danger': form.meta_keywords.length === 250
                        }">
                        You typed
                        {{ form.meta_keywords.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </div>

                <b-form-group id="input-group-9" label="Meta Description" label-for="input-9" class="position-relative">
                  <b-form-textarea id="input-9" v-model="form.meta_description" placeholder="Enter Meta Description"
                    rows="3" :maxlength="400"></b-form-textarea>
                  <div class="text-right">
                    <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                      :class="{
                        'badge-success': form.meta_description.length !== 400,
                        'badge-danger': form.meta_description.length === 400
                      }">
                      You typed
                      {{ form.meta_description.length }} out of 400 chars.
                    </p>
                  </div>
                </b-form-group>
                <b-form-group id="example-date" class="col-2 pl-0">
                  <label for="date">Date <span style="color: red">*</span></label>
                  <b-form-input id="date" v-model="form.date" :min="disabledDates()" type="date" :class="{
                    'is-invalid': form3 && $v.form.date.$invalid,
                  }"></b-form-input>
                  <div v-if="form3 && !$v.form.date.required" class="invalid-feedback">
                    Date is required.
                  </div>
                </b-form-group>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import podcastMixin from "../../../mixins/ModuleJs/podcast";
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  mixins: [MixinRequest, podcastMixin],
  data() {
    return {
      submitted: false,
      store: false,
      form3: false,
      title1: "Add Podcast",
      title2: "Edit Podcast",
      items: [
        {
          text: "Back",
          href: "/podcast",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    PageHeader,
    Layout,
    FormWizard,
    TabContent,
  },
  validations: {
    form: {
      partner_id: { required },
      community_id: { required },
      title: { required },
      audio_link: { required },
      date: { required },

    },
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.audio_link.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.store = true;
      if (this.$v.form.community_id.$invalid || this.$v.form.partner_id.$invalid || this.$v.form.date.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 10);
    },
  },
};
</script>
